import React from 'react';
import Layout from '../../components/Layout/index';
import { withIntl } from '../../i18n/index';
import ExtendedTrialPage from '../../templates/ExtendedTrial/index';
const host = process.env.GATSBY_HOST;

const extendedTrial = {
  title: 'extendedTrialTitle',
  subTitle: 'extendedTrialSubTitle',
  form: {
    title: '',
    type: 'extendedTrial',
    fields: [
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      },
      {
        type: 'message',
        label: 'extendedTrialFormMessage',
        value: ''
      },
    ],
    subText: 'extendedTrialFormSubText',
    button: {
      text: 'extendedTrialFormButtonText',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

const ExtendedTrial = (props) => (
  <Layout {...props}>
    <ExtendedTrialPage
      data={extendedTrial}
    />
  </Layout>
);


export default withIntl(ExtendedTrial);


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './ExtendedTrial.scss';
import Utils from '../../utils/utils';
import Form from '../../components/Form';
import Clients from '../../components/Clients/Clients';

class ExtendedTrial extends PureComponent {
  render() {
    const { intl, data } = this.props;

    return (
      <div>
        <div className="et__wrapper">
          <div className="grid-wrapper et">
            <div className="col-7 et__text">
              <div className="et__title">
                <h1>
                  {intl.formatMessage({id: data.title})}
                </h1>
              </div>
              <div className="et__subTitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.subTitle}))
                  }}
                />
              </div>
            </div>
            <div className="col-5 et__image">
              <Form data={data.form} />
            </div>
          </div>
          <Clients background={'#fff'}/>
        </div>
      </div>
    );
  }
}


ExtendedTrial.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(ExtendedTrial);

